import React, { useState, useContext, useEffect } from "react";
import { ethers } from "ethers";
import { Flex, Button, FormControl, Box, FormLabel, Heading, Input, Text, Spacer, Switch, Circle, Image } from "@chakra-ui/react";
import { SettingsIcon } from '@chakra-ui/icons';
import { isBrowser, isMobile} from "react-device-detect";  
import { isIOS, isAndroid } from "react-device-detect";
import SwitchTab from "./components/SwitchTab";
import FlexButton from "./components/FlexButton";
import { useWeb3 } from "./hooks/useWeb3";
import { usePAN } from "./hooks/usePAN";
import { useIcon } from "./hooks/useIcon";


import { AppContext } from "./context";

import logo_cronos from "./assets/cronos_light_bg.png";
import logo from "./assets/creampan.png";
import logo_color3_2 from "./assets/creampan_color3_2.png";
import logo_color2_2 from "./assets/creampan_color2_2.png";
import ptCRO_logo from "./assets/ptCRO.png";
import ytCRO_logo from "./assets/ytCRO.png";
import WCRO_logo from "./assets/WCRO.png";
import ptCROv2_logo from "./assets/ptCROv2.png";
import ytCROv2_logo from "./assets/ytCROv2.png";


function PAN() {
  const {
    web3Provider,
    selectedTab,
    ptCRO_WCRO_address,
    ytCRO_WCRO_address,
    PAN_WCRO_address,
    ptCRO_ADDRESS,
    ytCRO_ADDRESS,
    ptCROV2_ADDRESS,
    ytCROV2_ADDRESS,
    ptCROv2_WCRO_address,
    ytCROv2_WCRO_address,
    PAN_address,
    blockNumber
  } = useContext(AppContext);

  const { getTokenPrice, getPTCROPrice, getYTCROPrice, getPANPrice, getStakeAmount, getTotalStake, getRewards, getAPRFromServer, getTotalSupply, getBalance } = usePAN();
  const { handleExplorer } = useWeb3();
  const { CircleIcon } = useIcon();

  const tabs = ["Staking", "Pool"];

  const [stakeingList, setStakingList] = useState([
    {
      name: "PAN",
      tokenName: "PAN",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo: logo_color3_2,
      contractAddress: PAN_address,
      pid: "0",
      isPool: false
    },
    {
      name: "ptCRO V1",
      tokenName: "ptCRO",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo: ptCRO_logo,
      contractAddress: ptCRO_ADDRESS,
      pid: "1",
      isPool: false
    },
    {
      name: "ytCRO V1",
      tokenName: "ytCRO",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo: ytCRO_logo,
      contractAddress: ytCRO_ADDRESS,
      pid: "5",
      isPool: false
    },
    {
      name: "ptCRO V2",
      tokenName: "ptCROv2",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo: ptCROv2_logo,
      contractAddress: ptCROV2_ADDRESS,
      pid: "10",
      isPool: false
    },
    {
      name: "ytCRO V2",
      tokenName: "ytCROv2",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo: ytCROv2_logo,
      contractAddress: ytCROV2_ADDRESS,
      pid: "11",
      isPool: false
    }
  ]);

  const [poolList, setPoolList] = useState([
    {
      name: "PAN-WCRO",
      tokenName: "PAN",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo:  logo_color3_2,
      logo2: WCRO_logo,
      contractAddress: PAN_WCRO_address,
      totalLPSupply: "0",
      contractBalance: "0",
      pid: "4",
      isPool: true
    },
    {
      name: "ptCRO-WCRO",
      tokenName: "ptCRO",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo:  ptCRO_logo,
      logo2: WCRO_logo,
      contractAddress: ptCRO_WCRO_address,
      totalLPSupply: "0",
      contractBalance: "0",
      pid: "2",
      isPool: true
    },
    {
      name: "ytCRO-WCRO",
      tokenName: "ytCRO",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo:  ytCRO_logo,
      logo2: WCRO_logo,
      contractAddress: ytCRO_WCRO_address,
      totalLPSupply: "0",
      contractBalance: "0",
      pid: "3",
      isPool: true
    },
    {
      name: "ptCROv2-WCRO",
      tokenName: "ptCROv2",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo:  ptCROv2_logo,
      logo2: WCRO_logo,
      contractAddress: ptCROv2_WCRO_address,
      totalLPSupply: "0",
      contractBalance: "0",
      pid: "12",
      isPool: true
    },
    {
      name: "ytCROv2-WCRO",
      tokenName: "ytCROv2",
      myStake: "0",
      myReward: "0",
      totalStaked: "0",
      APR: "0",
      price: "0",
      logo:  ytCROv2_logo,
      logo2: WCRO_logo,
      contractAddress: ytCROv2_WCRO_address,
      totalLPSupply: "0",
      contractBalance: "0",
      pid: "13",
      isPool: true
    },
  ]);

  const updateContractBalance = async () => {
    for (let i=0; i < poolList.length; i++)
    {
      //console.log(poolList[i].contractAddress);
      poolList[i].totalLPSupply = await getTotalSupply(poolList[i].contractAddress);
      if (poolList[i].tokenName === "PAN") {
        poolList[i].contractBalance = await getBalance(PAN_address, poolList[i].contractAddress);
      }
      else if (poolList[i].tokenName === "ptCRO") {
        poolList[i].contractBalance = await getBalance(ptCRO_ADDRESS, poolList[i].contractAddress);
      }
      else if (poolList[i].tokenName === "ytCRO") {
        poolList[i].contractBalance = await getBalance(ytCRO_ADDRESS, poolList[i].contractAddress);
      }
      //V2
      else if (poolList[i].tokenName === "ptCROv2") {
        poolList[i].contractBalance = await getBalance(ptCROV2_ADDRESS, poolList[i].contractAddress);
      }
      else if (poolList[i].tokenName === "ytCROv2") {
        poolList[i].contractBalance = await getBalance(ytCROV2_ADDRESS, poolList[i].contractAddress);
      }
      //
    }
    //console.log(poolList);
  }

  const updateStakingList = (pid, newStakedAmount, newReward, newAPR, newTotalStaked, ptCRO_Price, ytCRO_Price, PAN_Price, ptCROv2_Price, ytCROv2_Price) => {
    var apr;

    setStakingList(prevStakingList => {
      return prevStakingList.map(item => {
        if (Number(item.pid) === pid) {
          if      (item.tokenName === "PAN") {
            apr = Number (newAPR).toFixed(2);
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: PAN_Price };
          }
          else if (item.tokenName === "ptCRO") {
            apr = Number(Number (newAPR) * Number(PAN_Price) / Number(ptCRO_Price)).toFixed(2);
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: ptCRO_Price };
          }
          else if (item.tokenName === "ytCRO") {
            apr = Number(Number (newAPR) * Number(PAN_Price) / Number(ytCRO_Price)).toFixed(2);
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: ytCRO_Price };
          }
          //V2
          else if (item.tokenName === "ptCROv2") {
            apr = Number(Number (newAPR) * Number(PAN_Price) / Number(ptCROv2_Price)).toFixed(2);
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: ptCROv2_Price };
          }
          else if (item.tokenName === "ytCROv2") {
            apr = Number(Number (newAPR) * Number(PAN_Price) / Number(ytCROv2_Price)).toFixed(2);
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: ytCROv2_Price };
          }
          //
          else {
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: "0", totalStaked: newTotalStaked };
          }
        }
          return item;
      });
    });
  }

  const updatePoolList = (pid, newStakedAmount, newReward, newAPR, newTotalStaked, ptCRO_Price, ytCRO_Price, PAN_Price, ptCROv2_Price, ytCROv2_Price) => {
    var apr;
  
    setPoolList( prevPoolList => {
      return prevPoolList.map(item => {

        if (Number(item.pid) === pid) {
          if      (item.tokenName === "PAN") {
            apr = Number(Number (newAPR) * ( Number(item.contractBalance) / Number(item.totalLPSupply) * ( 1 / 2) )).toFixed(2);
            //console.log(newAPR, item.contractBalance, item.totalLPSupply, apr);
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: 2*Number(PAN_Price) };
          }
          else if (item.tokenName === "ptCRO") {
            apr = Number(Number (newAPR) * ( Number(item.contractBalance) / Number(item.totalLPSupply) ) * Number(PAN_Price) / (2*Number(ptCRO_Price))).toFixed(2);
            //console.log(newAPR, PAN_Price, Number(ptCRO_Price), apr);
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: 2*Number(ptCRO_Price) };
          }
          else if (item.tokenName === "ytCRO") {
            apr = Number(Number (newAPR) * 2 * ( Number(item.contractBalance) / Number(item.totalLPSupply) ) * Number(PAN_Price) / (2*Number(ytCRO_Price))).toFixed(2);
            //console.log(newAPR, PAN_Price, Number(ytCRO_Price), apr);
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: 2*Number(ytCRO_Price) };
          }
          //V2
          else if (item.tokenName === "ptCROv2") {
            apr = Number(Number (newAPR) * ( Number(item.contractBalance) / Number(item.totalLPSupply) ) * Number(PAN_Price) / (2*Number(ptCROv2_Price))).toFixed(2);
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: 2*Number(ptCROv2_Price) };
          }
          else if (item.tokenName === "ytCROv2") {
            apr = Number(Number (newAPR) * 2 * ( Number(item.contractBalance) / Number(item.totalLPSupply) ) * Number(PAN_Price) / (2*Number(ytCROv2_Price))).toFixed(2);
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: apr, totalStaked: newTotalStaked, price: 2*Number(ytCROv2_Price) };
          }
          //
          else {
            return { ...item, myStake: newStakedAmount, myReward: newReward, APR: newAPR, totalStaked: newTotalStaked };
          }

        }
          return item;
      });
    });
  }

  //regular update states
  useEffect(() => {
    let intervalId;

    const updateStatus = async () => {
      var res;
      var ptCRO_Price;
      var ytCRO_Price;
      var PAN_Price;
      var newStakedAmount;
      var newReward;
      var newAPR;
      var newTotalStaked;
      //V2
      var ptCROv2_Price;
      var ytCROv2_Price;
      //
      if (web3Provider) {
        try {
          res         = await getAPRFromServer();
          ptCRO_Price = await getPTCROPrice();
          ytCRO_Price = await getYTCROPrice();
          PAN_Price   = await getPANPrice();
          //V2
          ptCROv2_Price = await getTokenPrice(ptCROV2_ADDRESS);
          ytCROv2_Price = await getTokenPrice(ytCROV2_ADDRESS);
          //
          await updateContractBalance();
          //console.log(PAN_Price, ptCRO_Price, ytCRO_Price);
          for (let i=0; i < 14; i++) //V2
          {
            newStakedAmount = await getStakeAmount(i); //V2 HERE!! HERE!!
            newReward       = await getRewards(i);
            newAPR          = res[i].apr;
            newTotalStaked  = await getTotalStake(i);
            //console.log(i, newStakedAmount, newReward, newAPR, newTotalStaked);
            updateStakingList(i, newStakedAmount, newReward, newAPR, newTotalStaked, ptCRO_Price, ytCRO_Price, PAN_Price, ptCROv2_Price, ytCROv2_Price);
            updatePoolList   (i, newStakedAmount, newReward, newAPR, newTotalStaked, ptCRO_Price, ytCRO_Price, PAN_Price, ptCROv2_Price, ytCROv2_Price);
          }
          //console.log(stakeingList);
        } catch (error) {
          console.error(error);
        }
      }
    };
  
    if (web3Provider) {
      updateStatus();
      intervalId = setInterval(updateStatus, 5000);
    }
  
    return () => clearInterval(intervalId);
  }, [web3Provider]);
  ///////////////////////

      return (
        <Box
            align="center"                                 
            display="flex"                                 
            flexDirection="column"                         
            justifyContent="space-between"                 
            height={(isMobile) ? (web3Provider) ? "88.3vh" : "92.3vh"
                               : "92.3vh"}                                              
            css={{                                         
              overflowY: "scroll",                         
              "&::-webkit-scrollbar": {                    
                width: (isMobile) ? '0px' : '16px',                             
              },                                           
              "&::-webkit-scrollbar-track": {              
                backgroundColor: "transparent",            
              },                                           
              "&::-webkit-scrollbar-thumb": {              
                backgroundColor: "rgba(205, 102, 0, 0.5)", 
                borderRadius: "20px",                      
                border: "4px solid transparent",           
                backgroundClip: "content-box",             
              },                                           
              "&::-webkit-scrollbar-thumb:hover": {        
                backgroundColor: "rgba(205, 102, 0, 0.8)", 
              },                                           
            }} 
          >
          <Flex justifyContent="center" alignItems="center">
          <Box position="relative" top="60px" borderRadius="2rem" bg="orange.200" border="2px" borderColor="orange.200" p="0.4rem">
          <SwitchTab tabs={tabs} onChange={()=>{}} />
          </Box>
          </Flex>

          <Box 
            w={(isMobile) ? "95vw" : "60rem"}
            mx="auto"
            position="relative"
            top={(isMobile) ? "90px" : "60px"}
            boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
            borderRadius="1.25rem"
          >
            <Box mt="0rem">
            { (selectedTab===0) && stakeingList.map((val, index) => (
              <FlexButton key={val.name} name={val.name} icon={val.logo} icon2={val.logo} isFirst={(index===0) ? true : false} isLast={(index===(stakeingList.length-1)) ? true : false} isPool={false} pid={val.pid}
                          myStake={val.myStake} myReward={val.myReward} APR={val.APR} totalStaked={val.totalStaked} contractAddress={val.contractAddress} />
            ))}
            { (selectedTab===1) &&     poolList.map((val, index) => (
              <FlexButton key={val.name} name={val.name} icon={val.logo} icon2={val.logo2} isFirst={(index===0) ? true : false} isLast={(index===(poolList.length-1))     ? true : false} isPool={true} pid={val.pid}
                          myStake={val.myStake} myReward={val.myReward} APR={val.APR} totalStaked={val.totalStaked} contractAddress={val.contractAddress} />
            ))}
            </Box>
          </Box>
        
        <Flex
          alignItems="center"
          justifyContent="space-evenly">
          <Text mt="10rem" fontSize='35px' fontWeight="bold" fontFamily="Arial">
          </Text>
        </Flex>

        <Flex bottom="0px" justify="space-between" alignItems="center" width="99vw" mb={(isAndroid) ? "0.7rem" : "0.2rem"}>
          
          <Flex mr="16px" w="90px">
          </Flex>

          <Flex alignItems="center">
            <Text fontSize={(isMobile) ? '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.4rem" >powered by</Text>
            <Box width="85px" height="30px">
              <Image src={logo_cronos} fit="contain"/>
            </Box>
          </Flex>

          <Flex alignItems="center" w={(web3Provider) ? "" : "90px"} >
            {web3Provider && (
            <Flex alignItems="center">
            <Button h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.3rem" p="0" onClick={() => handleExplorer( ("/block/" + blockNumber) )}>{blockNumber}</Button>
            <CircleIcon boxSize={3} color="rgb(55, 160, 105)" mr="0px"/>
            </Flex>
            )}
            {!web3Provider && (
            <Flex alignItems="center">
            <Text h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="transparent" mr="0.3rem" p="0">{blockNumber}</Text>
            <CircleIcon boxSize={3} color="transparent" mr="0px"/>
            </Flex>
            )}
          </Flex>
        </Flex>

        </Box>
      );
  
}

export default PAN;
