import React, { useState, useContext, useEffect } from "react";
import { ethers } from "ethers";
import { Flex, Box, Text, Circle, Image, Divider, Button, Switch, Input } from "@chakra-ui/react";
import {ChevronUpIcon, ChevronDownIcon, ExternalLinkIcon} from "@chakra-ui/icons";
import { useWeb3 } from "../hooks/useWeb3";
import { usePAN } from "../hooks/usePAN";
import CustomSwitch  from "./CustomSwitch";
import MintButtonSmall from "./MintButtonSmall";
import ClaimButtonSmall from "./ClaimButtonSmall";
import { isBrowser, isMobile} from "react-device-detect";

import { AppContext } from "../context";

export default function FlexButton({ name, icon, icon2, isFirst, isLast, isPool, pid, contractAddress, myStake, myReward, APR, totalStaked, stakeTokenPrice }) {
  const { handleAddLP, handleExplorer } = useWeb3();
  const { checkAllowance, getBalance, getStakeAmount, claimRewards, depositToken, withdrawToken, enterStakeToken, leaveStakeToken, approveToken } = usePAN();

  const [detailOpen, setDetailOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [stakeChecked, setStakeChecked] = useState(true);
  const [allowed, setAllowed] = useState(true);
  const [amount, setAmount] = useState("");

  const toggleSwitch = () => setStakeChecked(!stakeChecked);
  const changeDetailOpen = () => setDetailOpen(!detailOpen);
  
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const {
    web3Provider,
    walletAddress,
    price_WCRO,
    price_PAN,
    MasterBaker_ADDRESS,
  } = useContext(AppContext);

  const checkStatus = async (amount) => {
    console.log("check");
    if (web3Provider) {
      try {
        var result = await checkAllowance(contractAddress, amount, MasterBaker_ADDRESS);

        setAllowed( result ); 
        console.log(amount, allowed, result, contractAddress);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const setAmountMAX = async () => {
    var max;
    var currentAddress = await web3Provider.getSigner().getAddress();
    if (stakeChecked) {
      max = await getBalance(contractAddress, currentAddress);
      //console.log(Number(max));
      //setAmount( (Math.floor(Number(max)*10)/10) );
      console.log(max);
      console.log((Math.floor(Number(max)*10)/10));
      setAmount( ((Math.floor(Number(max)*10)/10).toString()) );
      checkStatus(max);
    }
    else {
      max = await getStakeAmount(pid);
      //console.log(Number(max));
      setAmount( ((Math.floor(Number(max)*10)/10).toString()) );
      checkStatus(max);
    }
  }

  return (
    <Box>
      {!isMobile &&
      <Box bg="white" borderRadius={ isFirst ? "1.25rem 1.25rem 0rem 0rem" : (isLast ? "0rem 0rem 1.25rem 1.25rem" : "0rem 0rem 0rem 0rem")}>

        <Flex key={name} justifyContent="space-between" p="0.6rem 0.6rem 0.6rem 0.6rem" alignItems="center">
          <Flex alignItems="center" w="14rem">
            <Circle size="40px" position="absolute" left="10px" bg="white" color="white" className="image-container">
              <Image src={icon} fit="contain" borderRadius="1.25rem" />
            </Circle>
            {isPool &&
            <Circle size="40px" position="absolute" left="35px" bg="blue.100" color="white" className="image-container">
              <Image src={icon2} fit="contain" borderRadius="1.25rem" />
            </Circle>
            }
            <Text ml={isPool ? "70px" : "45px"} fontWeight="bold" >{name}</Text>
          </Flex>
          
          <Box align="left" w="180px">
            <Text fontSize="16px" color="#551a8b" fontWeight="bold">My stake:</Text> 

            <Flex alignItems="center">
              <Text fontSize="18px" fontWeight="bold">
                {Number(myStake).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}
              </Text>
              <Text fontSize="14px" ml="1px" fontWeight="bold" color="gray.500">
                &nbsp;${Number(Number(myStake)*Number(stakeTokenPrice)*Number(price_WCRO)).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}
              </Text>
            </Flex>

          </Box>
          <Box align="left" w="140px">
            <Text fontSize="16px" color="#551a8b" fontWeight="bold">Rewards:</Text>   

            <Flex alignItems="center">
              <Text fontSize="18px" fontWeight="bold">
                {Number(myReward).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}
              </Text>
              <Text fontSize="14px" ml="1px" fontWeight="bold" color="gray.500">
                &nbsp;${Number(Number(myReward)*Number(price_PAN)*Number(price_WCRO)).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}
              </Text>
            </Flex>

          </Box>
          <Box align="left" w="90px">
            <Text fontSize="16px" color="#551a8b" fontWeight="bold">APR:</Text>          
            <Text fontSize="18px" fontWeight="bold">{Number(APR).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Text>
          </Box>
          <Box align="left" w="180px">
            <Text fontSize="16px" color="#551a8b" fontWeight="bold">Total staked:</Text> 

            <Flex alignItems="center">
              <Text fontSize="18px" fontWeight="bold">
                {Number(totalStaked).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}
              </Text>
              <Text fontSize="14px" ml="1px" fontWeight="bold" color="gray.500">
                &nbsp;${Number(Number(totalStaked)*Number(stakeTokenPrice)*Number(price_WCRO)).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}
              </Text>
            </Flex>

            </Box>
          <Flex justifyContent="right" w="20px" ml="3rem">
          <Text fontSize="15px" color="blue.600" fontWeight="bold" cursor="pointer" onClick={changeDetailOpen}> {detailOpen ? "Hide" : "Details"} </Text>
            {detailOpen ? ( <ChevronUpIcon   fontSize="1.25rem" cursor="pointer" color="blue.600" onClick={changeDetailOpen} /> )
                        : ( <ChevronDownIcon fontSize="1.25rem" cursor="pointer" color="blue.600" onClick={changeDetailOpen} /> )
            }
          </Flex>
        </Flex>

        {((!isLast)||detailOpen) && <Divider color="gray"></Divider>}

        {detailOpen &&
        <Box bg="rgb(246,246,246)" borderRadius={ isLast ? "0rem 0rem 1.25rem 1.25rem" : "0rem 0rem 0rem 0rem" }>
          <Flex justifyContent="space-evenly" alignItems="center" p="1.2rem">

            <Box border="0px" borderColor="gray.300" borderRadius="0.65rem" mr="1.5rem" p={1} display="flex" flexDirection="column" alignItems="start">
              { isPool &&  
              <Flex alignItems="center">
              <Text fontSize="14px" color="blue.700" fontWeight="bold" mr="0.3rem" style={{ cursor: 'pointer' }} onClick={handleAddLP}>Add LP</Text>
                <ExternalLinkIcon boxSize={5} color="blue.700" style={{ cursor: 'pointer' }} onClick={handleAddLP}/>
              </Flex>
              }
              <Flex alignItems="center">
                <Text fontSize="14px" color="blue.700" fontWeight="bold" mr="0.3rem" style={{ cursor: 'pointer' }} onClick={() => handleExplorer( ("/address/"+contractAddress) )}>View Contract</Text>
                <ExternalLinkIcon boxSize={5} color="blue.700" style={{ cursor: 'pointer' }} onClick={() => handleExplorer( ("/address/"+contractAddress) )}/>
              </Flex>
            </Box>


            <Box border="2px" borderColor="gray.300" borderRadius="0.65rem" mr="1.5rem" p="0.5rem 0.5rem 0.5rem" width="16rem">
              <Flex mb="0.5rem" justifyContent="left" alignItems="center">
                <Text fontSize="13px" color="blue.600" fontWeight="bold">EARNED</Text>
              </Flex>

              <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p="0.5rem 0.5rem 0.5rem"
                  borderRadius="1.0rem" border="0.06rem solid rgb(237, 238, 242)"
                  width="100%" height="40px"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box>
                    <Text
                      color="blue.800"
                      fontSize="13px"
                      fontWeight="bold">
                      PAN:
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontWeight="500"
                      fontSize="1.1rem"
                      width="100%"
                      textAlign="right"
                      bg="rgb(247, 248, 250)"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      color="gray.600"
                    >{myReward}</Text>
                  </Box> 
              </Flex>

              <ClaimButtonSmall onClick={async () => { var tx;
                                                      tx = await claimRewards(pid);
                                                      if (typeof tx !== 'undefined') {
                                                        setIsClaimLoading(true);
                                                        await tx.wait();
                                                        await delay(10000);
                                                        setIsClaimLoading(false);
                                                      }
                                                    }}
                                isLoading={isClaimLoading} text="Claim"/>
            </Box>

            <Box border="2px" borderColor="gray.300" borderRadius="0.65rem" p="0.5rem 0.5rem 0.5rem" width="16rem">
              <Flex justifyContent="space-between" alignItems="center" mb="0.5rem">
                <Text fontSize="13px" w="65px" color={stakeChecked ? "blue.600" : "rgb(205,44,44)" } fontWeight="bold"> {stakeChecked ? "STAKE" : "UNSTAKE" }</Text>
                <Flex justifyContent="space-between" alignItems="center">
                  <Button fontSize="11px" w="41px" h="18px" color={stakeChecked ? "blue.600" : "rgb(205,44,44)" } fontWeight="bold" borderRadius="0.45rem" border="1px" mr="0.3rem" onClick={setAmountMAX}>MAX</Button>
                  <CustomSwitch checkedBackground="blue.600" uncheckedBackground="rgb(205,44,44)" isChecked={stakeChecked} toggleSwitch={toggleSwitch} />
                </Flex>
              </Flex>

              <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p="0.5rem 0.5rem 0.5rem"
                  borderRadius="1.0rem" border="0.06rem solid rgb(237, 238, 242)"
                  width="100%" height="40px"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box>
                    <Text
                      color="black"
                      fontSize="13px"
                      fontWeight="bold">
                      Amount:
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      placeholder="0.0"
                      fontWeight="500"
                      fontSize="1.1rem"
                      width="95%"
                      size="19rem"
                      textAlign="right"
                      bg="rgb(247, 248, 250)"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      type="text"
                      inputMode="decimal"
                      color="black"
                      value={amount}
                      style={{ boxShadow: "none" }}
                      onChange={ (e) => {const newAmount = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); setAmount(newAmount); checkStatus(newAmount); } }
                    />
                  </Box> 
              </Flex>
              
              { (allowed===true) && <MintButtonSmall onClick={(stakeChecked ? async () => { var tx;
                                                                                            if (Number(pid)===0) {
                                                                                              tx = await enterStakeToken(amount);
                                                                                              if (typeof tx !== 'undefined') {
                                                                                                setIsLoading(true);
                                                                                                await tx.wait();
                                                                                                await delay(10000);
                                                                                                setIsLoading(false);
                                                                                              }
                                                                                            }
                                                                                            else {
                                                                                              tx = await depositToken (pid, amount);
                                                                                              if (typeof tx !== 'undefined') {
                                                                                                setIsLoading(true);
                                                                                                await tx.wait();
                                                                                                await delay(10000);
                                                                                                setIsLoading(false);
                                                                                              }
                                                                                            }
                                                                                          }
                                                                            : async () => { var tx;
                                                                                            if (Number(pid)===0) {
                                                                                              tx = await leaveStakeToken(amount);
                                                                                              if (typeof tx !== 'undefined') {
                                                                                                setIsLoading(true);
                                                                                                await tx.wait();
                                                                                                await delay(10000);
                                                                                                setIsLoading(false);
                                                                                              }
                                                                                            }
                                                                                            else {
                                                                                              tx = await withdrawToken(pid, amount);
                                                                                              if (typeof tx !== 'undefined') {
                                                                                                setIsLoading(true);
                                                                                                await tx.wait();
                                                                                                await delay(10000);
                                                                                                setIsLoading(false);
                                                                                              }
                                                                                            }
                                                                                          } )}
                                                    isLoading={isLoading}
                                                    text={stakeChecked ? "Stake" : "Unstake" }
                                                    stakeChecked={stakeChecked}
                                                    amount={amount}
                                                    allowed={allowed}             
                                                    />
              }
              { (allowed===false) && <MintButtonSmall onClick={(stakeChecked ? async () => { var tx = await approveToken(contractAddress, MasterBaker_ADDRESS, amount);
                                                                                            if (typeof tx !== 'undefined') {
                                                                                              setIsLoading(true);
                                                                                              await tx.wait();  //await delay(25000); //Delay 25s
                                                                                              await delay(10000);
                                                                                              setIsLoading(false);
                                                                                              checkStatus(amount);
                                                                                            }
                                                                                            }
                                                                            : (Number(pid)===0) ? () => leaveStakeToken(amount) : () => withdrawToken(pid, amount) ) }
                                                    isLoading={isLoading}
                                                    text={stakeChecked ? "Approve" : "Unstake" }
                                                    stakeChecked={stakeChecked}
                                                    amount={amount}
                                                    allowed={allowed}             
                                                    />
              }
            </Box>

          </Flex>
          {!isLast && <Divider color="gray"></Divider>}
        </Box>
        }

    </Box>
  }


{isMobile &&
      <Box bg="white" width="95vw" align="center" borderRadius={ isFirst ? "1.25rem 1.25rem 0rem 0rem" : (isLast ? "0rem 0rem 1.25rem 1.25rem" : "0rem 0rem 0rem 0rem")}>

        <Flex key={name} justifyContent="space-between" p="0.6rem 0.6rem 0.6rem 0.6rem" alignItems="center">
          <Flex alignItems="center" mt="0.6rem" w="18rem">
            <Circle size="40px" position="absolute" left="10px" bg="white" color="white" className="image-container">
              <Image src={icon} fit="contain" borderRadius="1.25rem" />
            </Circle>
            {isPool &&
            <Circle size="40px" position="absolute" left="35px" bg="blue.100" color="white" className="image-container">
              <Image src={icon2} fit="contain" borderRadius="1.25rem" />
            </Circle>
            }
            <Text ml={isPool ? "70px" : "45px"} fontWeight="bold">{name}</Text>
          </Flex>

          <Flex justifyContent="right" mr="0.5rem">
            {detailOpen ? ( <ChevronUpIcon   fontSize="1.5rem" cursor="pointer" color="blue.600" onClick={changeDetailOpen} /> )
                        : ( <ChevronDownIcon fontSize="1.5rem" cursor="pointer" color="blue.600" onClick={changeDetailOpen} /> )
            }
          </Flex>
        </Flex>
            
        <Flex key={name} justifyContent="center" p="0.3rem 0.6rem 0rem 0.6rem" alignItems="center">

          <Box align="left" w="180px">
            <Text fontSize="16px" color="#551a8b" fontWeight="bold">My stake:</Text> 

            <Flex alignItems="center">
              <Text fontSize="18px" fontWeight="bold">
                {Number(myStake).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}
              </Text>
              <Text fontSize="14px" ml="1px" fontWeight="bold" color="gray.500">
                &nbsp;${Number(Number(myStake)*Number(stakeTokenPrice)*Number(price_WCRO)).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}
              </Text>
            </Flex>

          </Box>
          <Box align="left" w="180px" mr="0px">
            <Text fontSize="16px" color="#551a8b" fontWeight="bold">Rewards:</Text>   

            <Flex alignItems="center">
              <Text fontSize="18px" fontWeight="bold">
                {Number(myReward).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}
              </Text>
              <Text fontSize="14px" ml="1px" fontWeight="bold" color="gray.500">
                &nbsp;${Number(Number(myReward)*Number(price_PAN)*Number(price_WCRO)).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}
              </Text>
            </Flex>
          </Box>
        </Flex>
        
        <Flex key={name} justifyContent="center" p="0.3rem 0.6rem 0.3rem 0.6rem" alignItems="center">
          <Box align="left" w="180px">
            <Text fontSize="16px" color="#551a8b" fontWeight="bold">APR:</Text>          
            <Text fontSize="18px" fontWeight="bold">{Number(APR).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}%</Text>
          </Box>
          <Box align="left" w="180px">
            <Text fontSize="16px" color="#551a8b" fontWeight="bold">Total staked:</Text> 

            <Flex alignItems="center">
              <Text fontSize="18px" fontWeight="bold">
                {Number(totalStaked).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}
              </Text>
              <Text fontSize="14px" ml="1px" fontWeight="bold" color="gray.500">
                &nbsp;${Number(Number(totalStaked)*Number(stakeTokenPrice)*Number(price_WCRO)).toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1})}
              </Text>
            </Flex>

            </Box>
        </Flex>

        {((!isLast)||detailOpen) && <Divider color="gray"></Divider>}

        {detailOpen &&
        <Box bg="rgb(246,246,246)" borderRadius={ isLast ? "0rem 0rem 1.25rem 1.25rem" : "0rem 0rem 0rem 0rem" } display="flex" flexDirection="column" alignItems="center">

            <Box border="0px" borderColor="gray.300" borderRadius="0.65rem" p="0.5rem 0.5rem 0.5rem" ml="1.0rem" mb="0.5rem" display="flex" flexDirection="column" alignItems="center">
              { isPool &&  
              <Flex alignItems="center">
              <Text fontSize="14px" color="blue.700" fontWeight="bold" mr="0.3rem" mb="0.3rem" style={{ cursor: 'pointer' }} onClick={handleAddLP}>Add LP</Text>
                <ExternalLinkIcon boxSize={5} color="blue.700" mb="0.3rem" style={{ cursor: 'pointer' }} onClick={handleAddLP}/>
              </Flex>
              }
              <Flex alignItems="center">
                <Text fontSize="14px" color="blue.700" fontWeight="bold" mr="0.3rem" style={{ cursor: 'pointer' }} onClick={() => handleExplorer( ("/address/"+contractAddress) )}>View Contract</Text>
                <ExternalLinkIcon boxSize={5} color="blue.700" style={{ cursor: 'pointer' }} onClick={() => handleExplorer( ("/address/"+contractAddress) )}/>
              </Flex>
            </Box>


            <Box border="2px" borderColor="gray.300" borderRadius="0.65rem" p="0.5rem 0.5rem 0.5rem" mb="0.5rem" width="16rem">
              <Flex mb="0.5rem" justifyContent="left" alignItems="center">
                <Text fontSize="13px" color="blue.600" fontWeight="bold">EARNED</Text>
              </Flex>

              <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p="0.5rem 0.5rem 0.5rem"
                  borderRadius="1.0rem" border="0.06rem solid rgb(237, 238, 242)"
                  width="100%" height="40px"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box>
                    <Text
                      color="blue.800"
                      fontSize="13px"
                      fontWeight="bold">
                      PAN:
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontWeight="500"
                      fontSize="1.1rem"
                      width="100%"
                      textAlign="right"
                      bg="rgb(247, 248, 250)"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      color="gray.600"
                    >{myReward}</Text>
                  </Box> 
              </Flex>

              <ClaimButtonSmall onClick={async () => { var tx;
                                                      tx = await claimRewards(pid);
                                                      if (typeof tx !== 'undefined') {
                                                        setIsClaimLoading(true);
                                                        await tx.wait();
                                                        await delay(10000);
                                                        setIsClaimLoading(false);
                                                      }
                                                    }}
                                isLoading={isClaimLoading} text="Claim"/>
            </Box>

            <Box border="2px" borderColor="gray.300" borderRadius="0.65rem" p="0.5rem 0.5rem 0.5rem" mb="0.5rem" width="16rem">
              <Flex justifyContent="space-between" alignItems="center" mb="0.5rem">
                <Text fontSize="13px" w="65px" color={stakeChecked ? "blue.600" : "rgb(205,44,44)" } fontWeight="bold"> {stakeChecked ? "STAKE" : "UNSTAKE" }</Text>
                <Flex justifyContent="space-between" alignItems="center">
                  <Button fontSize="11px" w="41px" h="18px" color={stakeChecked ? "blue.600" : "rgb(205,44,44)" } fontWeight="bold" borderRadius="0.45rem" border="1px" mr="0.3rem" onClick={setAmountMAX}>MAX</Button>
                  <CustomSwitch checkedBackground="blue.600" uncheckedBackground="rgb(205,44,44)" isChecked={stakeChecked} toggleSwitch={toggleSwitch} />
                </Flex>
              </Flex>

              <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p="0.5rem 0.5rem 0.5rem"
                  borderRadius="1.0rem" border="0.06rem solid rgb(237, 238, 242)"
                  width="100%" height="40px"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box>
                    <Text
                      color="black"
                      fontSize="13px"
                      fontWeight="bold">
                      Amount:
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      placeholder="0.0"
                      fontWeight="500"
                      fontSize="1.1rem"
                      width="95%"
                      size="19rem"
                      textAlign="right"
                      bg="rgb(247, 248, 250)"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      type="text"
                      inputMode="decimal"
                      color="black"
                      value={amount}
                      style={{ boxShadow: "none" }}
                      onChange={ (e) => {const newAmount = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); setAmount(newAmount); checkStatus(newAmount); } }
                    />
                  </Box> 
              </Flex>
              
              { (allowed===true) && <MintButtonSmall onClick={(stakeChecked ? async () => { var tx;
                                                                                            if (Number(pid)===0) {
                                                                                              tx = await enterStakeToken(amount);
                                                                                              if (typeof tx !== 'undefined') {
                                                                                                setIsLoading(true);
                                                                                                await tx.wait();
                                                                                                await delay(10000);
                                                                                                setIsLoading(false);
                                                                                              }
                                                                                            }
                                                                                            else {
                                                                                              tx = await depositToken (pid, amount);
                                                                                              if (typeof tx !== 'undefined') {
                                                                                                setIsLoading(true);
                                                                                                await tx.wait();
                                                                                                await delay(10000);
                                                                                                setIsLoading(false);
                                                                                              }
                                                                                            }
                                                                                          }
                                                                            : async () => { var tx;
                                                                                            if (Number(pid)===0) {
                                                                                              tx = await leaveStakeToken(amount);
                                                                                              if (typeof tx !== 'undefined') {
                                                                                                setIsLoading(true);
                                                                                                await tx.wait();
                                                                                                await delay(10000);
                                                                                                setIsLoading(false);
                                                                                              }
                                                                                            }
                                                                                            else {
                                                                                              tx = await withdrawToken(pid, amount);
                                                                                              if (typeof tx !== 'undefined') {
                                                                                                setIsLoading(true);
                                                                                                await tx.wait();
                                                                                                await delay(10000);
                                                                                                setIsLoading(false);
                                                                                              }
                                                                                            }
                                                                                          } )}
                                                    isLoading={isLoading}
                                                    text={stakeChecked ? "Stake" : "Unstake" }
                                                    stakeChecked={stakeChecked}
                                                    amount={amount}
                                                    allowed={allowed}             
                                                    />
              }
              { (allowed===false) && <MintButtonSmall onClick={(stakeChecked ? async () => { var tx = await approveToken(contractAddress, MasterBaker_ADDRESS, amount);
                                                                                            if (typeof tx !== 'undefined') {
                                                                                              setIsLoading(true);
                                                                                              await tx.wait();  //await delay(25000); //Delay 25s
                                                                                              await delay(10000);
                                                                                              setIsLoading(false);
                                                                                              checkStatus(amount);
                                                                                            }
                                                                                            }
                                                                            : (Number(pid)===0) ? () => leaveStakeToken(amount) : () => withdrawToken(pid, amount) ) }
                                                    isLoading={isLoading}
                                                    text={stakeChecked ? "Approve" : "Unstake" }
                                                    stakeChecked={stakeChecked}
                                                    amount={amount}
                                                    allowed={allowed}             
                                                    />
              }
            </Box>

          {!isLast && <Divider color="gray"></Divider>}
        </Box>
        }

    </Box>
  }

  </Box>

  );
}
